export const MARG_ORDER_STATUS = {
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  DELETED: 'DELETED',
  PICKED: 'PICKED',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
}

export const BILL_RG_STATUS_STATUS_OPTIONS = {
  CREATED: 'Created',
  PACKING_INITIATED: 'Packing Initiated',
  PACKING_COMPLETED: 'Packing Completed',
}
