<template>
  <div>
    <span
      :class="`text-${statusVariant} border-${statusVariant}`"
      class="border rounded bg-white status-badge"
    >
      <span class="font-weight-bold"> {{ data }} </span>
    </span>
  </div>
</template>

<script>
import { MARG_ORDER_STATUS } from '@/utils/consts'

export default {
  props: {
    data: { type: String, required: true },
  },
  computed: {
    statusVariant() {
      switch (this.data) {
        case MARG_ORDER_STATUS.CONFIRMED:
          return 'primary'

        case MARG_ORDER_STATUS.PICKED:
          return 'warning'

        case MARG_ORDER_STATUS.DELIVERED:
          return 'success'

        case MARG_ORDER_STATUS.DELETED:
        case MARG_ORDER_STATUS.FAILED:
          return 'danger'

        default:
          return 'secondary'
      }
    },
  },
}
</script>
  <style lang="scss" scoped>
  .status-badge {
    padding: 2px 12px;
    font-size: 12px;
  }
  </style>
