<template>
  <b-modal
    v-model="showModal"
    title="Re-create Orders on Marg"
    :size="submited ? 'lg' : 'sm'"
    :centered="!submited"
    :scrollable="submited"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
  >
    <div v-if="!submited">
      <p>Are you sure you want to re-create selected orders on marg?</p>
      <p>Selected orders will be marked as <StatusChip
        data="FAILED"
        class="d-inline mx-25"
      />  and new orders will be <StatusChip
        data="CREATED"
        class="d-inline mx-25"
      /></p>
    </div>

    <b-alert
      variant="success"
      :show="placedItems.length > 0 ? true : false"
    >
      <div
        class="alert-body"
      >
        <p>
          Following orders re-created successfully:
        </p>
        <p
          v-for="(item, itemKey) of placedItems"
          :key="itemKey"
        >
          {{ item.order_number }}: {{ item.message }}
        </p>
      </div>
    </b-alert>

    <b-alert
      variant="danger"
      :show="failedItems.length > 0 ? true : false"
    >
      <div
        class="alert-body"
      >
        <p>
          Failed to place following orders:
        </p>
        <p
          v-for="(item, itemKey) of failedItems"
          :key="itemKey"
        >
          {{ item.order_number }}: {{ item.message }}
        </p>
      </div>
    </b-alert>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          class="mr-1"
          variant="secondary"
          @click="showModal = false"
        >
          Close
        </b-button>
        <b-button
          v-if="!submited"
          variant="primary"
          :disabled="submitting"
          @click="handleConfirm"
        >
          Re-create
          <b-spinner
            v-if="submitting"
            small
            label="Small Spinner"
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BSpinner, BButton, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import StatusChip from '@/components/UI/StatusChip.vue'

export default {
  components: {
    BModal,
    BSpinner,
    BButton,
    BAlert,
    StatusChip,
  },
  props: {
    itemIds: { type: Array, required: true },
  },
  data() {
    return {
      submitting: false,
      showModal: true,
      submited: false,
      results: [],
    }
  },
  computed: {
    failedItems() {
      return this.results.filter(item => item.error)
    },
    placedItems() {
      return this.results.filter(item => !item.error)
    },
  },
  methods: {
    async handleConfirm() {
      this.submitting = true

      axios.post('/marg/recreate_orders', {
        ids: this.itemIds,
      }).then(res => {
        this.results = res.data.results
        this.submited = true
        this.submitting = false
        this.$emit('updated')
      }).catch(error => {
        const message = error?.response?.data?.detail || 'Failed to Re-create orders on marg!'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.submitting = false
      })
    },
  },
}
</script>
