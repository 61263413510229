<template>
  <b-table-simple
    responsive
    hover
    class="compact-table"
  >
    <b-tbody>
      <b-tr>
        <b-th>Delivery Mode</b-th>
        <b-td>{{ item.delivery_mode_display }}</b-td>
        <b-th>&nbsp;</b-th>
        <b-td>&nbsp;</b-td>
      </b-tr>
      <b-tr>
        <template v-if="detailFields.includes('bus_name_number')">
          <b-th>
            Bus Name and Number
          </b-th>
          <b-td>
            {{ item.bus_name_number }}
          </b-td>
        </template>
        <template v-if="detailFields.includes('conductor_name')">
          <b-th>Conductor Name</b-th>
          <b-td>{{ item.conductor_name }}</b-td>
        </template>
      </b-tr>
      <b-tr>
        <template v-if="detailFields.includes('conductor_mobile')">
          <b-th>Conductor Mobile No.</b-th>
          <b-td>{{ item.conductor_mobile }}</b-td>
        </template>
        <template v-if="detailFields.includes('receiving_time')">
          <b-th>Receiving Time</b-th>
          <b-td>
            {{ item.receiving_time && formatedDate(item.receiving_time, 'DD-MM-YYYY LT') || '-' }}
          </b-td>
        </template>
      </b-tr>
      <b-tr v-if="detailFields.includes('destination_address')">
        <b-th>Destination Address</b-th>
        <b-td colspan="3">
          {{ item.destination_address }}
        </b-td>
      </b-tr>
      <b-tr v-if="detailFields.includes('bus_photo')">
        <b-th>Bus Photo</b-th>
        <b-td colspan="3">
          <a
            v-if="item.bus_photo"
            :href="item.bus_photo"
            target="_blank"
          >
            <span>View Photo</span>
          </a>
          <span v-else>-</span>
        </b-td>
      </b-tr>
      <b-tr v-if="detailFields.includes('remarks')">
        <b-th>Remarks</b-th>
        <b-td colspan="3">
          {{ item.remarks }}
        </b-td>
      </b-tr>
      <b-tr>
        <template v-if="detailFields.includes('mr_name')">
          <b-th>
            MR Name
          </b-th>
          <b-td>
            {{ item.mr_name }}
          </b-td>
        </template>
        <template v-if="detailFields.includes('mr_number')">
          <b-th>
            MR Number
          </b-th>
          <b-td>
            {{ item.mr_number || '-' }}
          </b-td>
        </template>
      </b-tr>
      <b-tr>
        <template>
          <b-th>
            OTP Verified
          </b-th>
          <b-td>
            {{ item.delivery_otp && item.delivery_otp.verified ? 'Yes' : 'No' }}
          </b-td>
        </template>
        <template v-if="item.delivery_otp">
          <b-th>
            OTP Mobile
          </b-th>
          <b-td>
            {{ item.delivery_otp.mobile }} - {{ item.delivery_otp.receiver_name }}
          </b-td>
        </template>
        <template v-else>
          <b-th />
          <b-td />
        </template>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import dateMixin from '@/mixins/dateMixin'
import {
  BTableSimple, BTbody, BTd, BTr, BTh,
} from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
  },
  mixins: [dateMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    detailFields() {
      const deliveryMode = this.item?.delivery_mode

      if (deliveryMode === 'TRANSPORT_DELIVERY') {
        return ['bus_name_number', 'conductor_name', 'conductor_mobile', 'receiving_time', 'destination_address', 'bus_photo']
      }

      if (deliveryMode === 'MR_COLLECTION') {
        return ['mr_name', 'mr_number']
      }

      if (['DELIVERED_TO_OTHER_WHOLESALER', 'DELIVERED_TO_OTHER_RETAILER', 'DELIVERD_WITH_REMARK'].includes(deliveryMode)) {
        return ['remarks']
      }

      return []
    },
  },
}
</script>
