<template>
  <b-row>
    <b-col md="2">
      <b-form-group
        label="Order Number"
      >
        <b-form-input
          v-model="orderNumber"
          type="text"
          placeholder="Search Order Number"
          @input="debouncedFiltersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Marg Order ID"
      >
        <b-form-input
          v-model="margOrderId"
          type="text"
          placeholder="Marg Order ID"
          @input="debouncedFiltersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Party"
      >
        <custom-v-select
          v-model="selectedParties"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="partyOptions"
          label="text"
          placeholder="Select Parties"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          :reduce="option => option.id"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Statuses"
      >
        <custom-v-select
          v-model="statuses"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          placeholder="Select Statuses"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Placed By">
        <custom-v-select
          v-model="placedByUsers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="placedByOptions"
          placeholder="Select Placed By"
          multiple
          :close-on-select="false"
          label="fullname"
          :reduce="(data => data.id)"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Created Date Range"
      >
        <flat-picker
          v-model="createdDateRange"
          placeholder="Select Created Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import { debounce } from 'lodash'
import { MARG_ORDER_STATUS } from '@/utils/consts'
import dateMixin from '@/mixins/dateMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    CustomVSelect,
    FlatPicker,
    BFormInput,
  },
  mixins: [dateMixin],
  data() {
    return {
      partyOptions: [],
      statusOptions: [
        MARG_ORDER_STATUS.CREATED, MARG_ORDER_STATUS.CONFIRMED,
        MARG_ORDER_STATUS.DELETED, MARG_ORDER_STATUS.PICKED,
        MARG_ORDER_STATUS.DELIVERED, MARG_ORDER_STATUS.FAILED,
      ],
      placedByOptions: [],
      orderNumber: null,
      margOrderId: null,
      selectedParties: [],
      statuses: [],
      placedByUsers: [],
      createdDateRange: `${this.formatedDate(new Date(), 'YYYY-MM-DD')} to ${this.formatedDate(new Date(), 'YYYY-MM-DD')}`,
    }
  },
  watch: {
    createdDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/buyers_options')
        .then(res => {
          this.partyOptions = res.data.data
        })

      axios.get('/delivery/users_list')
        .then(res => {
          this.placedByOptions = res.data
        })
    },
    debouncedFiltersChangeHandler: debounce(function dummyName() {
      this.filtersChangeHandler()
    }, 500),
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.orderNumber) {
        filtersdata.order_number = this.orderNumber
      }
      if (this.margOrderId) {
        filtersdata.marg_order_id = this.margOrderId
      }
      if (this.selectedParties) {
        filtersdata.parties = this.selectedParties
      }
      if (this.statuses) {
        filtersdata.statuses = this.statuses
      }
      if (this.placedByUsers) {
        filtersdata.placed_by = this.placedByUsers
      }
      if (this.createdDateRange) {
        const [dateFrom, dateTo] = this.createdDateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.created_date_from = dateFrom
          filtersdata.created_date_to = dateTo
        }
      }

      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
