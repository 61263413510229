<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    scrollable
    title="Order Details"
    @hidden="$emit('modal-closed')"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <b-table-simple
        responsive
        class="compact-table"
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Order Number
            </b-th>
            <b-td>
              {{ data.order_number }}
            </b-td>
            <b-th>
              Marg Order ID
            </b-th>
            <b-td>
              {{ data.marg_order_id }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Party
            </b-th>
            <b-td>
              {{ data.party__identifier }}
            </b-td>
            <b-th>
              Total Items
            </b-th>
            <b-td>
              {{ formateNumber(data.total_items) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Total Amount
            </b-th>
            <b-td>
              {{ formateNumber(data.total_amount) }}
            </b-td>
            <b-th>
              Status
            </b-th>
            <b-td>
              {{ data.status }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Placed By
            </b-th>
            <b-td>
              {{ data.placed_by__fullname }}
            </b-td>
            <b-th>
              Created At
            </b-th>
            <b-td>
              {{ data.created_at ? formatedDate(data.created_at) : '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Bill No </b-th>
            <b-td> {{ data.bill_info ? data.bill_info.bill.bill_no : '' }} </b-td>
            <b-th>
              Remarks
            </b-th>
            <b-td>
              {{ data.remarks }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Bill Amount </b-th>
            <b-td> {{ data.bill_info ? data.bill_info.bill.amount : '' }} </b-td>
            <b-th> Attachments </b-th>
            <b-td v-if="data.bill_info">
              <span
                v-for="(item, index) in data.bill_info.bill.bill_attachments"
                :key="index"
              >
                <a
                  v-if="index === data.bill_info.bill.bill_attachments.length - 1"
                  :href="item.file"
                  target="_blank"
                >
                  <span>View File {{ index+ 1 }} </span>
                </a>
              </span>
            </b-td>
            <b-td v-else />
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="order-status-wrapper">
        <div class="timeline-wrapper mt-2">
          <hr class="timeline-line">
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between">
                <div class="text-center">
                  <status
                    class="timeline-status"
                    data="CREATED"
                  />
                  <b> {{ data.placed_by__fullname }}</b>
                  <div>
                    {{ data.created_at && formatedDate(data.created_at, 'DD-MM-YYYY LT') || '-' }}
                  </div>
                </div>
                <div
                  v-if="isOrderDeleted"
                  class="text-center"
                >
                  <status
                    data="DELETED"
                    class="timeline-status"
                  />
                  <div>
                    <b>&nbsp;</b>
                    <div class="mb-1">
                      {{ data.deleted_at && formatedDate(data.deleted_at, 'DD-MM-YYYY LT') || '' }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="!isOrderDeleted"
                  class="text-center"
                >
                  <status
                    data="CONFIRMED"
                    class="timeline-status"
                    :disabled="!data.bill_info || !data.bill_info.generated_at"
                  />
                  <div v-if="data.bill_info">
                    <b>{{ data.bill_info.generated_by }}</b>
                    <div class="mb-1">
                      {{ data.bill_info && formatedDate(data.bill_info.generated_at, 'DD-MM-YYYY LT') || '' }}
                    </div>
                  </div>
                  <span v-else>Pending</span>
                </div>
                <div
                  v-if="!isOrderDeleted && data.bill_info && data.bill_info.pick_up_time"
                  class="bg-white px-1"
                >
                  {{ data.bill_info.pick_up_time }}
                </div>
                <div
                  v-if="!isOrderDeleted"
                  class="text-center"
                >
                  <status
                    data="PICKED"
                    class="timeline-status"
                    :disabled="!isBillPicked"
                  />
                  <div v-if="isBillPicked">
                    <b> {{ data.bill_info.picked_by_fullname }} </b>
                    <div class="mb-1">
                      {{ data.bill_info.picked_at && formatedDate(data.bill_info.picked_at, 'DD-MM-YYYY LT') || '-' }}
                    </div>
                  </div>
                  <span v-else> Pending </span>
                </div>
                <div
                  v-if="!isOrderDeleted && data.bill_info && data.bill_info.delivery_time"
                  class="bg-white px-1"
                >
                  {{ data.bill_info.delivery_time }}
                </div>

                <div
                  v-if="!isOrderDeleted"
                  class="text-center"
                >
                  <status
                    data="DELIVERED"
                    class="timeline-status"
                    :disabled="!isBillDelivered"
                  />
                  <div v-if="isBillDelivered">
                    <b> {{ data.bill_info.delivered_by_fullname }} </b>
                    <div class="mb-1">
                      {{ data.bill_info.delivered_at && formatedDate(data.bill_info.delivered_at, 'DD-MM-YYYY LT') || '-' }}
                    </div>
                  </div>
                  <span v-else> Pending  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div
          v-if="data.bill_info && data.status == 'DELIVERED' "
          class="mt-2"
        >
          <BillDeliveryInfo :item="data.bill_info" />
        </div>
      </div>

      <h5 class="mt-2 mb-1">
        Order Items
      </h5>

      <b-table
        caption-top
        :items="data.order_items"
        :fields="orderItemsTableFields"
        responsive="sm"
        striped
        hover
        class="compact-table"
      >
        <template #cell(qty)="tableData">
          {{ formateNumber(tableData.item.qty) }}
        </template>
        <template #cell(free)="tableData">
          {{ formateNumber(tableData.item.free) }}
        </template>
        <template #cell(mrp)="tableData">
          {{ formateNumber(tableData.item.mrp) }}
        </template>
        <template #cell(rate)="tableData">
          {{ formateNumber(tableData.item.rate) }}
        </template>
        <template #cell(lot)="tableData">
          {{ tableData.item.lot == "0 + 0" ? '' : tableData.item.lot }}
        </template>
        <template #cell(exp)="tableData">
          {{ tableData.item.exp ? formatedDate(tableData.item.exp) : '-' }}
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow=false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BAlert, BSpinner, BTableSimple, BTbody, BTd, BTr, BTh, BTable,
  BRow,
  BCol,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import Status from '@/components/Delivery/DeliveryStatus/Status.vue'
import formatMixin from '@/mixins/formatMixin'
import BillDeliveryInfo from '@/components/Delivery/BillDeliveryInfo.vue'

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
    BTable,
    Status,
    BRow,
    BCol,
    BillDeliveryInfo,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      modalShow: true,
      loadingError: null,
      loading: true,
      orderItemsTableFields: [
        { key: 'code', label: 'CODE', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'qty', label: 'QTY', sortable: true },
        { key: 'free', label: 'Free', sortable: true },
        { key: 'unit', label: 'UNIT', sortable: true },
        { key: 'mrp', label: 'MRP', sortable: true },
        { key: 'rate', label: 'Rate', sortable: true },
        { key: 'batch', label: 'Batch', sortable: true },
        { key: 'exp', label: 'Exp', sortable: true },
        { key: 'lot', label: 'Lot', sortable: true },
      ],
    }
  },
  computed: {
    isOrderDeleted() {
      return this.data && this.data.status === 'DELETED'
    },
    isBillPicked() {
      return this.data && this.data.bill_info && (this.data.bill_info.picked_at || this.data.picked_by_fullname)
    },
    isBillDelivered() {
      return this.data && this.data.bill_info && (this.data.bill_info.delivered_at || this.data.delivered_by_fullname)
    },
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      axios.get(`/marg/orders/${this.itemId}`)
        .then(res => {
          this.data = res.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching order details!'
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.timeline-wrapper {
    position: relative;

}
.timeline-line {
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
    width: 90%;
}

.timeline-status {
    margin-bottom: 4px;
}
.order-status-wrapper{
  margin: auto;
  max-width: 1000px;
  min-width: 800px;
}
</style>
